<template>
  <section class="">
    <docs-create v-model="docs" title="Yangi dokument"></docs-create>

    <div class="container">
      <a-button class="bg-success text-white " @click="createDocs"
        >Dokument qo'shish</a-button
      >
    </div>

    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos document title, title_en,title_ru, title_uz va rasmni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import DocsCreate from '../../components/Document/DocsCreate.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    DocsCreate,
  },

  data() {
    return {
      inValid: false,
      docs: {
        title: '',
        title_en: '',
        title_ru: '',
        title_uz: '',
        image: '',
      },
    };
  },

  methods: {
    ...mapActions({
      postDocument: 'document/postDocument',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    createDocs() {
      const obj = {
        title: this.docs.title,
        title_en: this.docs.title_en,
        title_ru: this.docs.title_ru,
        title_uz: this.docs.title_uz,
        image: this.docs.image.files,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }

      this.postDocument(obj)
        .then(() => {
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Dokument qo'shildi :)",
          });

          this.docs = {
            title: '',
            title_en: '',
            title_ru: '',
            title_uz: '',
            image: '',
          };
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description:
              "Serverga dokument qo'shilmadi ): birozdan keyin urining" +
              ' ' +
              error.message,
          });
        });
    },
  },
};
</script>
